import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Columns from "../components/Columns/Columns"
import Layout from "../components/Layout/Layout"
import MainSection from "../components/MainSection/MainSection"
import Section from "../components/Section/Section"
import Seo from "../components/Seo/Seo"
import "../styles/about.scss"
import about__Image from "../images/ilustrations/aboutMainImage.svg"
import aboutStrip from "../images/bg/aboutStrip.png"

import theme from "../svg/about_theme.svg"
import QuoteSection from "../components/QuoteSection/QuoteSection"
//@ts-ignore
import Fade from "react-reveal/Fade"

const About = () => {
  return (
    <Layout>
      <Seo
        title="Who is Resistant Ai"
        description="We protect financial automation systems from manipulation, misuse and intentional attacks with AI."
      />
      <MainSection
        caption="About us"
        title="Who Are "
        titleHighlight="We?"
        text="We protect AI-based financial automation systems from manipulation, misuse and intentional attacks with AI."
        className="About__main"
        divided
        imageLink={about__Image}
        button={{
          link: "/contact",
          variant: "blue",
          text: "Get started",
          size: "large",
        }}
      ></MainSection>

      <Section className="About__discover" id="discover">
        <div className="w-wrap">
          <h2>What we <span className="highlighted">do</span></h2>
        </div>
        <div className="w-wrap">
          <div className="w1-2">
             <p>We discover and prevent financial fraud based on forged documents, manipulated transactions
              and stolen or synthetic identities.{" "}
             </p>
          </div>
          <div className="w1-2">
            <p>Our first company, Cognitive Security, was sold to Cisco in 2013. It provided the foundation for what is now Cisco’s Cognitive
          Threat Analytics (CTA) platform which protects some 25 million users in Fortune 1000 companies.</p>
          </div>
        </div>
      </Section>

      <Section>
        <h2>Meet <span className="highlighted">Our Team</span></h2>
        <Columns className="About__team">

          <div className="About__team-member">
            <div className="About__team-member__photo">
              <StaticImage
                src="../images/rehak.png"
                alt="member"
              ></StaticImage>
            </div>
            <h3 className="About__team-member__name">Martin Rehak</h3>
            <p className="About__team-member__position highlighted">CEO</p>
          </div>

          <div className="About__team-member">
            <div className="About__team-member__photo">
              <StaticImage
                src="../images/walton.png"
                alt="member"
              ></StaticImage>
            </div>
            <h3 className="About__team-member__name">Roger Walton</h3>
            <p className="About__team-member__position highlighted">CRO</p>
          </div>

          <div className="About__team-member">
            <div className="About__team-member__photo">
              <StaticImage
                src="../images/de_ward.png"
                alt="member"
              ></StaticImage>
            </div>
            <h3 className="About__team-member__name">Jan de Waard</h3>
            <p className="About__team-member__position highlighted">Sales Director</p>
          </div>

          <div className="About__team-member">
            <div className="About__team-member__photo">
              <StaticImage
                src="../images/lemonnier.png"
                alt="member"
              ></StaticImage>
            </div>
            <h3 className="About__team-member__name">Joe Lemonnier</h3>
            <p className="About__team-member__position highlighted">Product Marketing</p>
          </div>

          <div className="About__team-member">
            <div className="About__team-member__photo">
              <StaticImage
                src="../images/lothian.png"
                alt="member"
              ></StaticImage>
            </div>
            <h3 className="About__team-member__name">Kate Lothian</h3>
            <p className="About__team-member__position highlighted">Head of Marketing</p>
          </div>

          <div className="About__team-member">
            <div className="About__team-member__photo">
              <StaticImage
                src="../images/grill.png"
                alt="member"
              ></StaticImage>
            </div>
            <h3 className="About__team-member__name">Martin Grill</h3>
            <p className="About__team-member__position highlighted">Head, Resistant Transactions</p>
          </div>

          <div className="About__team-member">
            <div className="About__team-member__photo">
              <StaticImage
                src="../images/jusko.png"
                alt="member"
              ></StaticImage>
            </div>
            <h3 className="About__team-member__name">Jan Jusko</h3>
            <p className="About__team-member__position highlighted">
              Head, Resistant Documents
            </p>
          </div>
          <div className="About__team-member">
            <div className="About__team-member__photo">
              <StaticImage
                src="../images/stach.png"
                alt="member"
              ></StaticImage>
            </div>
            <h3 className="About__team-member__name">Josef Stach</h3>
            <p className="About__team-member__position highlighted">
              Head of Security & DPO
            </p>
          </div>          

          <div className="About__team-member">
            <div className="About__team-member__photo">
              <StaticImage
                src="../images/duchac.png"
                alt="member"
              ></StaticImage>
            </div>
            <h3 className="About__team-member__name">Tomas Duchac</h3>
            <p className="About__team-member__position highlighted">Head of Finance</p>
          </div>

          <div className="About__team-member">
            <div className="About__team-member__photo">
              <StaticImage
                src="../images/bocek.png"
                alt="member"
              ></StaticImage>
            </div>
            <h3 className="About__team-member__name">Pavel Bocek</h3>
            <p className="About__team-member__position highlighted">Head of Operations</p>
          </div>

          <div className="About__team-member">
            <div className="About__team-member__photo">
              <StaticImage
                src="../images/musashi.png"
                alt="member"
              ></StaticImage>
            </div>
            <h3 className="About__team-member__name">Musashi</h3>
            <p className="About__team-member__position highlighted">Head of Perimeter Security</p>
          </div>
        </Columns>


      </Section>

      <div className="About__quote__wrapper">
        <Section
          className="About__quote" sectionClass="patternedGray"
          text="With a background in network security which was recognized by the
        industry as best in class, Resistant AI has evolved a unique
        approach to security machine learning as a countermeasure to the
        on-going and increasing threat of financial crime."
        ></Section>
      </div>

      <Section>
        <div className="w-wrap">
          <h2 className="MainSection__title"><span className="highlighted">Our</span> History</h2>
        </div>
        <div className="About__history">
          <Fade up>
            <div className="About__history__row">
              <div className="About__history__text">
                <h3>Team Established</h3>
                <p>
                  Team is formed at Faculty of Electrical Engineering of the Czech
                  Technical University
                </p>
              </div>

              <div className="About__history__date dots">2006</div>
              <div className="About__history__image">
                <StaticImage
                  src="../images/map_1.jpeg"
                  alt="map_1"
                  height={270}
                ></StaticImage>
              </div>
            </div>
          </Fade>
          <Fade up>
            <div className="About__history__row--reversed">
              <div className="About__history__text">
                <h3>Cognitive Security</h3>
                <p>
                  Cognitive Security spins off from Czech Technical University.
                </p>
              </div>
              <div className="About__history__date dots">2009</div>
              <div className="About__history__image">
                <StaticImage
                  src="../images/map_2.jpeg"
                  alt="map_1"
                  height={270}
                ></StaticImage>
              </div>
            </div>
          </Fade>
          <Fade up>
            <div className="About__history__row">
              <div className="About__history__text">
                <h3>Credo Ventures Investment</h3>
                <p>
                  Cognitive Security becomes Credo Ventures’ first investment
                  from its first fund.
                </p>
              </div>
              <div className="About__history__date dots">2011</div>
              <div className="About__history__image">
                <StaticImage
                  src="../images/map_3.png"
                  alt="map_1"
                  height={270}
                ></StaticImage>
              </div>
            </div>
          </Fade>

          <Fade up>
            <div className="About__history__row--reversed">
              <div className="About__history__text">
                <h3>Cisco Acquisition</h3>
                <p>
                  Cisco Acquires Cognitive Security to turn it into the core of
                  a cloud-based product for detection of novel and advanced
                  threats using machine learning and behavioral analytics.
                </p>

                <p>
                  Cognitive Security provided the foundation for what is now
                  Cisco’s Cognitive Threat Analytics (CTA) platform which protects
                  some 25 million users in Fortune 1000 companies.
                </p>
              </div>
              <div className="About__history__date dots">2013</div>
              <div className="About__history__image">
                <StaticImage
                  src="../images/map_4.jpeg"
                  alt="map_1"
                  height={270}
                ></StaticImage>
              </div>
            </div>
          </Fade>

          {/* <Fade up>
            <div className="About__history__row">
              <div className="About__history__text">
                <h3>Machine learning system</h3>
                <p>
                  Future Resistant AI founders hand-over the Cisco cloud-based
                  security machine learning system that protected more than 25
                  million corporate endpoints worldwide.
                </p>
              </div>
              <div className="About__history__date dots">2018</div>
              <div className="About__history__image">
                <StaticImage
                  src="../images/map_1.jpeg"
                  alt="map_1"
                  height={270}
                ></StaticImage>
              </div>
            </div>
          </Fade> */}

          <Fade up>
            <div className="About__history__row">
              <div className="About__history__text">
                <h3>Starting Resistant AI</h3>
                <p>
                  Resistant AI was created to address the security
                  vulnerabilities of AI in finance.
                </p>
              </div>
              <div className="About__history__date dots">2019</div>
              <div className="About__history__image">
                <StaticImage
                  src="../images/map_2.png"
                  alt="map_1"
                  height={270}
                ></StaticImage>
              </div>
            </div>
          </Fade>

          <Fade up>
            <div className="About__history__row--reversed">
              <div className="About__history__text">
                <h3>Seed Investment</h3>
                <p>
                  Resistant AI raises $2.75M from Index Ventures, Credo Ventures
                  and Seedcamp to develop its products for document fraud
                  detection and transaction monitoring oversight.
                </p>
              </div>
              <div className="About__history__date dots">2020</div>
              <div className="About__history__image">
                <StaticImage
                  src="../images/map_3.jpeg"
                  alt="map_1"
                  height={270}
                ></StaticImage>
              </div>
            </div>
          </Fade>

          <Fade up>
            <div className="About__history__row">
              <div className="About__history__text">
                <h3>Series A Funding</h3>
                <p>
                  Resistant AI raises $16.6M, led by GV (formerly Google
                  Ventures) with participation from existing investors
                  Index Ventures, Credo Ventures and Seedcamp, as well
                  as several angel investors specializing in financial
                  technology and security.

                </p>
              </div>
              <div className="About__history__date">2021</div>
              <div className="About__history__image">
                <StaticImage
                  src="../images/map_7.jpg"
                  alt="map_1"
                  height={270}
                ></StaticImage>
              </div>
            </div>
          </Fade>
        </div>
      </Section>

      <div className="About__photo">
        <img src={aboutStrip} alt="Team" />
      </div>

      <div className="About__QuoteSection">
      <QuoteSection
        className="Homepage__quote"
        quotes={[
          {
            restOfQuote:
              "Resistant AI dramatically reduces false positives and detects advanced fraud and manipulation at the same time. Under the protection of Resistant AI, our Nikita engine can fully focus on credit risk assessment excellence.",
            quoteBy: "Michal Krocil",
            companyName: "Twisto",
            position: "Chief Risk Officer",
            link: "https://www.twisto.cz/",
          },
          {
            restOfQuote:
              "We use Resistant AI’s technology in our KYC process. We greatly value the insights gained to help us validate the authenticity of documents submitted by customers.",
            quoteBy: "Keren Levy",
            companyName: "Payoneer",
            position: "COO",
            link: "https://www.payoneer.com",
          },
          {
            restOfQuote:
              "Resistant AI gives us reliable, real-time insights into our customer data. With high degrees of precision and accuracy.",
            quoteBy: "Dan Hagerty",
            companyName: "Habito",
            position: "CEO - Founder",
            link: "https://www.habito.com/",
          },
          {
            restOfQuote:
              "Resistant AI prevents the manipulation of invoices submitted to our marketplace. It allows our investors to trade in security and saves my team a huge amount of manual work.",
            quoteBy: "Alexandra Belkova",
            companyName: "Roger",
            position: "Head of Operations",
            link: "https://www.roger.cz",
          },
        ]}
      ></QuoteSection>
        <div className=""></div>
      </div>
    </Layout>
  )
}

export default About
